import Quill from "quill";
import "quill/dist/quill.core.css";
import Inline from "quill/blots/inline";
import { getImageUrl, type EmoteEntity } from "~/src/api";

export type UseQuillOptions = {
  placeholder?: string;
  toolbarId: string;
};

export class SpoilerBlot extends Inline {
  static override blotName = "spoiler";

  static override create(value?: any) {
    const spoilerShow = document.createElement("spoiler");
    spoilerShow.classList.add(
      "bg-neutral-400",
      "inline-block",
      "text-white",
      "p-0.5",
      "rounded",
    );
    return spoilerShow;
  }
}

type EmoteHtmlRegexGroups = {
  src: string;
  alt: string;
  emoteId: string;
  emoteKey: string;
};

export class EmoteBlot extends Inline {
  static override blotName = "emote";

  static override create(emote: EmoteEntity) {
    const src = getImageUrl(
      emote.id,
      "emote",
      emote.attributes.kind,
      emote.attributes.fileName,
    )!;

    const alt = emote.attributes.key;
    const emoteId = emote.id;
    const emoteKey = emote.attributes.key;

    return new DOMParser()
      .parseFromString(
        EmoteBlot.createImgHtmlString(src, alt, emoteId, emoteKey),
        "text/html",
      )
      .body.children.item(0) as HTMLElement;
  }

  /**
   * Returns the HTML !!!REPRESENTATION!!! of this Blot
   * Quill does not work properly with self-closing tags.
   * Shame...
   * Thankfully it can be worked around by implementing an undocumented and untyped method 🙏
   */
  html() {
    const src = this.domNode.getAttribute("src")!;
    const alt = this.domNode.getAttribute("alt")!;
    const emoteId = this.domNode.getAttribute("emote-id")!;
    const emoteKey = this.domNode.getAttribute("emote-key")!;
    return EmoteBlot.createImgHtmlString(src, alt, emoteId, emoteKey);
  }

  static createImgHtmlString(
    src: string,
    alt: string,
    emoteId: string,
    emoteKey: string,
  ) {
    return `<img class="editor-emote" src="${src}" alt="${alt}" emote-id="${emoteId}" emote-key="${emoteKey}">`;
  }

  static getRegexMatchGroups(string: string) {
    return EmoteBlot.htmlRegex.exec(string)?.groups as unknown as
      | EmoteHtmlRegexGroups
      | undefined;
  }

  static htmlRegex = new RegExp(
    /<img class="editor-emote" src="(?<src>[^"]*)" alt="(?<alt>[^"]*)" emote-id="(?<emoteId>[^"]*)" emote-key="(?<emoteKey>[^"]*)">/,
  );
}

let hasRegisteredBlots = false;

export function useQuill(
  target: MaybeRefOrGetter<HTMLElement | undefined>,
  options: UseQuillOptions,
) {
  if (!import.meta.server && !hasRegisteredBlots) {
    Quill.register(SpoilerBlot);
    Quill.register(EmoteBlot);
    hasRegisteredBlots = true;
  }

  const quill = computed(() => {
    const targetValue = toValue(target);
    if (!targetValue) return null;

    const quill = new Quill(targetValue, {
      placeholder: options.placeholder,
      formats: [
        "bold",
        "italic",
        "underline",
        "strike",
        "spoiler",
        "emote",
        "sticker",
      ],
      modules: {
        toolbar: {
          container: `#${options.toolbarId}`,
          handlers: {
            spoiler: function (value?: any) {
              quill!.format("spoiler", value);
            },
            emote: function (value?: any) {
              quill!.format("emote", value);
            },
            sticker: function (value?: any) {
              quill!.format("sticker", value);
            },
          },
        },
      },
    });

    return quill;
  });

  return quill;
}
