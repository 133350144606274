<template>
  <div>
    <div
      :class="{ disabled }"
      ref="inputDiv"
      class="relative cursor-text"
      v-bind:class="$attrs.class"
      @paste="(e) => emit('paste', e)"
      @click="quill?.focus()"
    ></div>
    <div v-if="addedSticker" class="relative inline-block mt-2">
      <AsyncImage
        class="max-h-40 max-w-40 h-40 w-40"
        :src="
          getImageUrl(
            addedSticker.id,
            'emote',
            'sticker',
            addedSticker.attributes.fileName,
          )
        "
        :alt="addedSticker.attributes.key"
      />
      <div class="absolute top-0 right-0">
        <NamiButton
          button-type="secondary"
          small
          pill
          :icon="IconX"
          @button-click="addedSticker = undefined"
        ></NamiButton>
      </div>
    </div>
    <slot name="between"></slot>
    <NamiDivider />
    <div :class="{ disabled }" :id="toolbarId" class="flex space-x-2 relative">
      <slot name="toolbarBefore"></slot>
      <ClientOnly>
        <Tooltip
          variant="custom"
          :position="!md && sm ? 'top' : 'bottom'"
          :size="md ? 512 : 256"
          fixed
          ref="emoteTooltip"
          manual
          :show="showEmoteComposer"
          @click-outside="showEmoteComposer = false"
        >
          <template #default>
            <NamiButton
              button-type="primary"
              :icon="IconMoodSmile"
              small
              pill
              :text="!showEmoteComposer"
              noWaves
              @buttonClick="() => (showEmoteComposer = !showEmoteComposer)"
            />
          </template>
          <template #tooltip>
            <div class="md:w-128 w-64 h-96 shadow-lg text-left">
              <EmoteComposer
                class="h-full border border-neutral-300 dark:border-neutral-700"
                ref="emoteComposer"
                :disabled-tabs="disabledEmoteTabs"
                :disabled-types="finalDisabledComposerTypes"
                @select-emoji="(character) => insertEmoji(character)"
                @select-emote="
                  (emote) => {
                    insertEmote(emote);
                    if (closeOnEmoteInput) showEmoteComposer = false;
                  }
                "
                @select-sticker="
                  (sticker) => {
                    addedSticker = sticker;
                    if (closeOnStickerInput) showEmoteComposer = false;
                  }
                "
              ></EmoteComposer>
            </div>
          </template>
        </Tooltip>
      </ClientOnly>
      <div class="flex items-center">
        <NamiButton
          button-type="primary"
          :icon="extraOpen ? IconChevronLeft : IconLetterCase"
          small
          pill
          text
          no-waves
          @buttonClick="() => (extraOpen = !extraOpen)"
        />
        <div
          class="overflow-hidden flex gap-2 transition-all"
          :class="{
            'absolute top-0 bg-white shadow rounded-full z-10': !sm,
            'max-w-0': !extraOpen,
            'max-w-[100%]': extraOpen,
          }"
        >
          <NamiButton
            v-if="!sm"
            button-type="primary"
            :icon="IconChevronLeft"
            small
            text
            pill
            noWaves
            @buttonClick="extraOpen = false"
          />
          <NamiButton
            class="ql-bold"
            button-type="primary"
            :icon="IconBold"
            force-icon-only
            small
            text
            pill
            noWaves
          />
          <NamiButton
            class="ql-italic"
            button-type="primary"
            :icon="IconItalic"
            small
            text
            pill
            noWaves
          />
          <NamiButton
            class="ql-strike"
            button-type="primary"
            :icon="IconStrikethrough"
            small
            text
            pill
            noWaves
          />
          <NamiButton
            class="ql-spoiler"
            button-type="primary"
            :icon="IconEyeOff"
            small
            text
            pill
            noWaves
          />
        </div>
      </div>
      <slot name="toolbarAfter"></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import Quill from "quill";
import "quill/dist/quill.core.css";
import Inline from "quill/blots/inline";

import { nanoid } from "nanoid";
import {
  IconBold,
  IconChevronLeft,
  IconEyeOff,
  IconItalic,
  IconLetterCase,
  IconMoodSmile,
  IconStrikethrough,
  IconX,
} from "@tabler/icons-vue";
import { marked, type TokenizerObject } from "marked";
import NamiButton from "~/components/nami/NamiButton.vue";
import EmoteComposer from "~/components/emote/EmoteComposer.vue";
import Tooltip from "~/components/Tooltip.vue";
import markedSpoiler from "~/src/md/spoiler";
import markedEmote from "~/src/md/emote";
import type {
  EmoteEntity,
  EmoteRelation,
  PopulateRelationship,
} from "~/src/api";
import type { TabValue } from "~/components/emote/EmoteComposer.vue";

const props = defineProps<{
  modelValue: string;
  disabled?: boolean;
  placeholder?: string;
  closeOnEmoteInput?: boolean;
  closeOnStickerInput?: boolean;
  disabledEmoteTypes?: ("emotes" | "stickers")[];
  disabledEmoteTabs?: TabValue[];
  maxEmotes?: number;
  maxStickers?: number;
}>();

const emit = defineEmits<{
  (e: "update:modelValue", v: string): void;
  (e: "paste", v: ClipboardEvent): void;
}>();

const emoteMatcher = /<emoji:(?<id>[^:]*):(?<key>[^:]*):(?<fileName>[^:>]*)>/g;

const emoteTooltip = ref<InstanceType<typeof Tooltip>>();
const emoteComposer = ref<InstanceType<typeof EmoteComposer>>();

watch(
  () => emoteTooltip.value?.isShown,
  () => {
    setTimeout(() => {
      emoteComposer.value?.updateTabsHighlightPosition();
    }, 100);
  },
);

const inputDiv = ref<HTMLDivElement>();
const toolbarId = `nami-rte-toolbar-${nanoid()}`;
const extraOpen = ref(false);
const showEmoteComposer = ref(false);
const addedSticker = ref<EmoteEntity | PopulateRelationship<EmoteRelation>>();

watch(showEmoteComposer, () => {
  quill.value?.focus();
});

watch(addedSticker, () => tryParseMd());

const { sm, md } = useBreakpoints();
const { getImageUrl } = useMediaLink();

const totalEmotesUsed = computed(() => {
  return [...props.modelValue.matchAll(emoteMatcher)].length;
});

const hasReachedMaxEmotes = computed(() => {
  if (!props.maxEmotes) return false;
  return totalEmotesUsed.value >= props.maxEmotes;
});

const finalDisabledComposerTypes = computed(() => {
  const initial = props.disabledEmoteTypes ?? [];
  if (hasReachedMaxEmotes.value) initial.push("emotes");
  if (addedSticker.value) initial.push("stickers");
  return initial;
});

type EmoteHtmlRegexGroups = {
  src: string;
  alt: string;
  emoteId: string;
  emoteKey: string;
};

// Quill.register(SpoilerBlot);
// Quill.register(EmoteBlot);

// TODO: suboptimal
// we set the inline order of spoilers to be the parent of strike, bold, and script
const unique = Array.from(new Set(Inline.order));
const itemsToInsert = ["italic", "bold", "strike", "spoiler"];
itemsToInsert.forEach((item) => unique.splice(Inline.order.indexOf(item), 1));
const insertIndex = unique.indexOf("code");
unique.splice(insertIndex, 0, ...itemsToInsert);
Inline.order = unique;

const quill = useQuill(inputDiv, {
  placeholder: props.placeholder,
  toolbarId: toolbarId,
});

onMounted(async () => {
  quill.value?.on("text-change", () => tryParseMd());

  inputDiv.value!.children[0]!.innerHTML = await mdParse.parse(
    props.modelValue,
  );
});

const insertEmoji = (input: string) => {
  if (!quill.value) return;

  const { index, length } = quill.value.getSelection() ?? {
    index: 0,
    length: 0,
  };
  if (length > 0) quill.value.deleteText(index, length, "user");
  quill.value.insertText(index, "  ", "user");
  quill.value.insertText(index + 1, input, "user");

  nextTick(() => {
    quill.value!.setSelection(index + 4, 0, "user");
  });
};

const insertEmote = (
  emote: EmoteEntity | PopulateRelationship<EmoteRelation>,
) => {
  if (!quill.value) return;

  const { index, length } = quill.value.getSelection() ?? {
    index: 0,
    length: 0,
  };
  if (length > 0) quill.value.deleteText(index, length, "user");
  const key = `<emoji:${emote.id}:${emote.attributes.key}:${emote.attributes.fileName}>`;

  quill.value.insertText(index, " ");
  quill.value.insertText(index, key, "emote", emote, "user");

  nextTick(() => {
    quill.value!.setSelection(index + key.length + 1, "user");
    quill.value?.focus();
  });
};

function htmlDecode(input: string) {
  var doc = new DOMParser().parseFromString(input, "text/html");
  return doc.documentElement.textContent!;
}

const tryParseMd = () => {
  const text = quill.value?.getSemanticHTML();

  if (!text) return;
  let parsed = text;
  // remove cursor
  parsed = parsed.replaceAll(/<span class="ql-cursor">.?<\/span>/g, "");
  // paragraph
  parsed = parsed.replaceAll(`<p>`, "").replaceAll(`</p>`, "\n");
  // spoiler
  parsed = parsed
    .replaceAll(/<spoiler class="[\w \-.]+?">/gi, "||")
    .replaceAll(`</spoiler>`, "||");
  // strikethrough
  parsed = parsed.replaceAll("<s>", "~~").replaceAll(`</s>`, "~~");
  // bold
  parsed = parsed.replaceAll("<strong>", "**").replaceAll(`</strong>`, "**");
  // italic
  parsed = parsed.replaceAll("<em>", "*").replaceAll(`</em>`, "*");

  let matchedEmoteGroups: EmoteHtmlRegexGroups | undefined;
  while ((matchedEmoteGroups = EmoteBlot.getRegexMatchGroups(parsed))) {
    const { src, emoteId, emoteKey } = matchedEmoteGroups;
    const fileName = src.split("/").at(-1)!;
    parsed = parsed.replace(
      EmoteBlot.htmlRegex,
      `|||emoji_start|||emoji:${emoteId}:${emoteKey}:${fileName}|||emoji_end|||`,
    );
  }

  parsed = htmlDecode(parsed);

  parsed = parsed.replaceAll("|||emoji_start|||", "<");
  parsed = parsed.replaceAll("|||emoji_end|||", ">");

  if (addedSticker.value) {
    parsed += " ";
    const id = addedSticker.value.id;
    const { key, fileName } = addedSticker.value.attributes;
    parsed += `<sticker:${id}:${key}:${fileName}>`;
  }

  emit("update:modelValue", parsed);
};

const ncTokenizer: TokenizerObject = {
  heading: () => undefined,
  hr: () => undefined,
  code: () => undefined,
  fences: () => undefined,
  codespan: () => undefined,
  link: () => undefined,
  reflink: () => undefined,
  table: () => undefined,
  autolink: () => undefined,
};

const renderer = new marked.Renderer();
renderer.link = (href) => href.href;

const mdParse = marked
  .use({
    breaks: true,
    tokenizer: ncTokenizer,
    renderer,
  })
  .use(markedSpoiler())
  .use(markedEmote());

// TODO: handle conversions
watch(
  () => props.modelValue,
  (v) => {
    if (!v.trim()) {
      const Delta = Quill.import("delta");
      quill.value?.setContents(new Delta());
    }
  },
);

watch(
  () => props.disabled,
  (v) => {
    quill.value?.enable(!v);
  },
);

defineExpose({
  reset: () => {
    addedSticker.value = undefined;
  },
  addedSticker,
});
</script>

<style>
.editor-emote {
  @apply h-6 w-6 align-top inline rounded-md;
}

.editor-sticker {
  @apply h-32 w-32 align-top rounded-md;
}
</style>
